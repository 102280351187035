import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import {
  AdminModuleSetup,
  RecruitOverview,
  OnboardingOverview,
  TrainingOverview,
  TrackingOverview,
  Shimmer,
} from './';
import styles from '../../../assets/styles/home.module.scss';
import {
  getOnboardingOverviewApi,
  getTrainingOverviewApi,
  getRecruitmentOverviewApi,
  getTrackingOverviewApi,
  getOverallDetailsApi,
} from '../apiServices';
import HomeCard from 'features/stream/UI/Analytics/HomeCard/HomeCard';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import HomeCardStatistics from './HomeCardStatistics';
import RoleplayHome from 'features/roleplay/Components/RoleplayHome/RoleplayHome';
import Filter from './Filters/Filter';
import stylesComponents from './Components.module.scss';
// import { useOnboardingStore } from 'features/onboarding/Onboarding.store';
import { useHomeStore } from '../Home.stores';
export default function AdminHomeContainer(props) {
  const [training, setTraining] = useState('');
  const [onboarding, setOnboarding] = useState('');
  const [recruitment, setRecruitment] = useState('');
  const [tracking, setTracking] = useState('');
  const [overallDetails, setOverallDetails] = useState('');

  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const { selectedDateFilter } = useHomeStore();

  const [loader, setLoader] = useState({
    onboarding: true,
    training: true,
    tracking: true,
    recruitment: true,
    overall: true,
  });
  useEffect(() => {
    setLoader((prevLoader) => ({
      ...prevLoader,
      overall: true,
    }));
    getOverallDetailsApi({
      time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilter.slug : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          overall: false,
        }));
        setOverallDetails(response.data);
      } else {
        setLoader((prevLoader) => ({
          ...prevLoader,
          overall: false,
        }));
      }
    });
  }, [selectedDateFilter]);

  useEffect(() => {
    if (!selectedOrg?.recruiting_module_enabled) {
      setLoader((prevLoader) => ({
        ...prevLoader,
        recruitment: false,
      }));
      return;
    }
    getRecruitmentOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          recruitment: false,
        }));
        setRecruitment(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setLoader((prevLoader) => ({
      ...prevLoader,
      onboarding: true,
    }));
    getOnboardingOverviewApi({
      time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilter.slug : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          onboarding: false,
        }));
        setOnboarding(response.data);
      } else {
        setLoader((prevLoader) => ({
          ...prevLoader,
          onboarding: false,
        }));
      }
    });
  }, [selectedDateFilter]);

  useEffect(() => {
    setLoader((prevLoader) => ({
      ...prevLoader,
      training: true,
    }));
    getTrainingOverviewApi({
      time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilter.slug : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          training: false,
        }));
        setTraining(response.data);
      }
    });
  }, [selectedDateFilter]);
  useEffect(() => {
    if (FeatureFlag.enabled('REMOVE_SALES_GOALS'))
      return setLoader((prevLoader) => ({
        ...prevLoader,
        tracking: false,
      }));
    getTrackingOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          tracking: false,
        }));
        setTracking(response.data);
      }
    });
  }, []);
  return (
    <>
      <div className={styles.pageTitle + ''}>
        {loader ? null : (recruitment.provider_configured || recruitment.no_of_jobs) &&
          onboarding.published_at &&
          training.setup_complete &&
          tracking.published_at ? (
          <>
            <img src={imageBasePath + 'home_icon.svg'} />
            <span>Home</span>
          </>
        ) : null}
      </div>
      <div className={styles.homePageContent}>
        {FeatureFlag.enabled('HOME_FILTERS') && (
          <div className={stylesComponents.title}>
            <Filter />
          </div>
        )}
        <Grid container spacing={3}>
          {!FeatureFlag.enabled('HOME_FILTERS') ? (
            loader &&
            (loader.onboarding ||
              loader.training ||
              loader.tracking ||
              loader.recruitment ||
              loader.overall) ? (
              <Shimmer type="OverallDetailItems" />
            ) : (
              <HomeCardStatistics overallDetails={overallDetails} />
            )
          ) : (
            <HomeCardStatistics overallDetails={overallDetails} />
          )}
          <Grid item xs={12}>
            {!FeatureFlag.enabled('HOME_FILTERS') ? (
              loader &&
              (loader.onboarding || loader.training || loader.tracking || loader.recruitment) ? (
                <Shimmer type="PageTitle" />
              ) : (
                <span className={`${styles.pageTitle} heading4`}>Team Progress</span>
              )
            ) : (
              <span className={`${styles.pageTitle} heading4`}>Team Progress</span>
            )}
          </Grid>
          {selectedOrg?.recruiting_module_enabled && (
            <Grid item xs={12} md={6}>
              {recruitment ? (
                recruitment.no_of_jobs || recruitment.provider_configured ? (
                  <RecruitOverview recruitment={recruitment} />
                ) : (
                  <AdminModuleSetup
                    moduleData={{
                      icon: `${imageBasePath}home_recruit_featured.svg`,
                      description: ` View your sales team recruitment from sourcing to offer process. You have several choices to integrate and track progress`,
                      id: `recruiting`,
                      link: '/recruitment',
                    }}
                  />
                )
              ) : (
                <Shimmer type="AdminModuleSetup" />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {onboarding ? (
              onboarding.published_at ? (
                <OnboardingOverview onboarding={onboarding} />
              ) : (
                <AdminModuleSetup
                  moduleData={{
                    icon: `${imageBasePath}home_company_featured.svg`,
                    description: `Integrating a new sales colleague into your company is critical. Build checklists with your company history, vision and culture to help them be productive`,
                    id: `onboarding`,
                    progress: {
                      completed: onboarding.content_added,
                      total: onboarding.total_steps,
                    },
                    link: '/onboarding',
                  }}
                  type="onboarding"
                />
              )
            ) : (
              <Shimmer type="AdminModuleSetup" />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {training ? (
              training.published_modules < training.total_modules && !training.setup_complete ? (
                <AdminModuleSetup
                  moduleData={{
                    icon: `${imageBasePath}home_training_featured.svg`,
                    description: `Train your team with the latest products and services with a rich training library, taking your sales team to the next level`,
                    id: `training`,
                    link: '/training',
                    progress: {
                      completed: training.published_modules,
                      total: training.total_modules,
                    },
                  }}
                  type="training"
                />
              ) : (
                <TrainingOverview training={training} />
              )
            ) : (
              <Shimmer type="AdminModuleSetup" />
            )}
          </Grid>
          {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
            <Grid item xs={12} md={6}>
              {tracking ? (
                tracking.published_at ? (
                  <TrackingOverview overview={tracking.overview[0]} />
                ) : (
                  <AdminModuleSetup
                    moduleData={{
                      icon: `${imageBasePath}home_track_featured.svg`,
                      description: `Define organizational and salesperson goals to keep track of your revenue goals. Helps you to better manage your goals and track consistently. No more excuses!`,
                      id: `goal tracking`,
                      link: '/tracking',
                      progress: {
                        completed: tracking.content_adeded,
                        total: tracking.total_categories,
                      },
                    }}
                  />
                )
              ) : (
                <Shimmer type="AdminModuleSetup" />
              )}
            </Grid>
          )}

          {FeatureFlag.enabled('STREAM') &&
            Subscription.allow({ key: Subscription.keys.stream, openModal: false }) && (
              <Grid item xs={12} md={6}>
                <HomeCard user="admin" />
              </Grid>
            )}

          <RoleplayHome graph />
        </Grid>
      </div>
    </>
  );
}
