import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toastFlashMessage } from 'utils';
import { useChangeSalesRepViewMutation, useGetSalesRepViewQuery } from './SalesRepView.queries';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';

const SUPPORTED_PATHS = ['/training'];

export const useSalesRepView = ({ requestValue = false } = {}) => {
  const location = useLocation();

  const isPathSupported = useMemo(() => {
    try {
      return SUPPORTED_PATHS.includes(location?.pathname?.match(/^\/[^/]+/)[0]);
    } catch (error) {
      return false;
    }
  }, [location]);

  const toastSuccessAdmin = () => {
    toastFlashMessage('Changed to Admin view', 'success');
  };
  const toastSuccessRep = () => {
    toastFlashMessage('Changed to Rep view', 'success');
  };

  const toastUnsupported = () => {
    let pathName = 'home';
    try {
      pathName = location.pathname?.match(/^\/[^/]+/)[0]?.replace('/', '');
    } catch (error) {}
    toastFlashMessage(
      `Sales Rep view is not available on ${pathName}. Switched back to admin view.`,
      'disabled'
    );
  };
  const enabled = FeatureFlag.enabled('SALES_REP_POV') && Organization.isAdmin();
  const { data: isActive = false, isLoading } = useGetSalesRepViewQuery({
    requestValue,
    enabled,
  });
  const loading = useMemo(() => {
    if (enabled) return isLoading;
    return false;
  }, [isLoading, enabled]);
  const { mutate, isLoading: mutationLoading } = useChangeSalesRepViewMutation();

  const switchOff = ({ withToast = false }) => {
    if (mutationLoading) return;
    mutate({ status: false }, { onSuccess: () => withToast && toastSuccessAdmin() });
  };
  const switchOn = () => {
    if (mutationLoading) return;
    mutate({ status: true }, { onSuccess: toastSuccessRep });
  };

  return { isPathSupported, isActive, isLoading: loading, toastUnsupported, switchOff, switchOn };
};
