import { create } from 'zustand';
import DateTime from 'infrastructure/Datetime';
import {timeOptions} from 'constants'
export const useRoleplayStore = create((set, get) => ({
  pagination: { number_of_pages: 0 },
  // Assign roleplays
  assignModalOpen: false,
  openAssignModal: () => set(() => ({ assignModalOpen: true })),
  closeAssignModal: () => set(() => ({ assignModalOpen: false })),
  scenariosList: [],
  setScenariosList: (scenariosList = []) => set(() => ({ scenariosList })),
  formDataAssignRoleplay: {
    scenarios: [],
    deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    value: 0
  },
  setFormDataAssignRoleplay: (formDataAssignRoleplay = {}) =>
    set(() => ({ formDataAssignRoleplay })),
  roleplayView: {},
  setRoleplayView: (roleplayView = []) => set(() => ({ roleplayView })),
  scenarioSelected: {},
  setScenarioSelected: (scenario = {}) => {
    const scenarioSelected = scenario?.uuid
      ? {
          ...scenario,
          label: scenario?.name,
          name: scenario?.name,
          value: scenario?.uuid,
          description: scenario?.description,
          example_video: scenario?.example_video,
        }
      : {};

    // Handle retake variables for this scenario
    const { isRetakeQualified, responseRetake, showRetake } = getRetakeData(scenarioSelected);
    return set(() => ({ scenarioSelected, isRetakeQualified, responseRetake, showRetake }));
  },
  currentRoleplay: [],
  setCurrentRoleplay: (currentRoleplay = []) => set(() => ({ currentRoleplay })),
  reviewScenarioSelected: {},
  setReviewScenarioSelected: (reviewScenarioSelected = {}) =>
    set(() => ({ reviewScenarioSelected })),
  formDataScenarioReview: { comment: '' },
  setFormDataScenarioReview: (formDataScenarioReview = {}) =>
    set(() => ({ formDataScenarioReview })),

  // Retake
  responseRetake: {},
  showRetake: false,
  isRetakeQualified: false,
  setResponseRetake: (responseRetake = []) => set(() => ({ responseRetake })),
  secondAttempt: false,
  showSecondAttempt: () => set(() => ({ secondAttempt: true })),
  hideSecondAttempt: () => set(() => ({ secondAttempt: false })),
  formDataCreateRoleplay: {
    scenarios: [{name: '', id: 1}],
    rating_criterials: [{name: '', id: 1}],
    deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
  },
  setFormDataCreateRoleplay: (formDataCreateRoleplay = {}) =>
    set(() => ({ formDataCreateRoleplay })),
  userWithRoleplay: [], 
  setUserWithRoleplay: (userWithRoleplay = []) => set(() => ({ userWithRoleplay })),
  isRoleplayAssignedToMe: false,
  showRoleplayAssignedToMe: () => set(() => ({ isRoleplayAssignedToMe: true })),
  hideRoleplayAssignedToMe: () => set(() => ({ isRoleplayAssignedToMe: false })),
  selectedDateFilterRoleplay: timeOptions.find(opt=>opt.value===2),
  setSelectedDateFilterRoleplay: (selectedDateFilterRoleplay = {}) => set(() => ({ selectedDateFilterRoleplay })),
}));

const getRetakeData = (scenario = {}) => {
  const responseRetake = scenario?.response_retake || {};
  const showRetake = Object.keys(responseRetake).length > 0;

  let isRetakeQualified = false;
  if (Object.keys(scenario).length > 0)
    if (scenario?.response_retake?.rating_criterials.length > 0) isRetakeQualified = true;

  return { responseRetake, showRetake, isRetakeQualified };
};
