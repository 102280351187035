import React, { useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import throttle from 'lodash.throttle';
import Slider from 'react-slick';
import Organization from 'infrastructure/Organization';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import {
  useGetRoleplaysToCompleteQuery,
  useGetRoleplaysToReviewQuery,
} from 'features/roleplay/Roleplay.queries';
import RoleplaysListShimmer from './RoleplaysListShimmer';
import AddAssignButton from './AddAssignButton';
import DashboardRoleplayCard from './DashboardRoleplayCard';
import { imageBasePath } from 'constants';
import styles from './RoleplayDashboardList.module.scss';
import { InputSelect } from 'shared_ui_components';
import { TO_REVIEW_OPTIONS } from 'features/roleplay/RoleplayConstants';
import Filter from 'features/home/components/Filters/Filter';
import { FeatureFlag } from 'controllers/_exports';

const RoleplaysDashboardsList = () => {
  const [filter, setFilter] = useState(TO_REVIEW_OPTIONS[0]);
  const onFilterChange = (opt) => setFilter(opt);
  const isRep = Organization.isRep();
  const open = useRoleplayStore((state) => state?.openAssignModal);

  // Get roleplays according to user role
  const { data: roleplaysToComplete = [], isLoading: loadingRoleplaysToComplete } =
    useGetRoleplaysToCompleteQuery({
      enabled: isRep,
    });

  const { data: roleplaysToReview = [], isLoading: loadingRoleplaysToReview } =
    useGetRoleplaysToReviewQuery({
      enabled: !isRep,
    });

  const data = useMemo(() => {
    if (isRep) return roleplaysToComplete?.length > 0 ? roleplaysToComplete : [];
    return roleplaysToReview?.length > 0 ? roleplaysToReview : [];
  }, [roleplaysToComplete, roleplaysToReview, isRep]);

  const filteredData = useMemo(() => {
    if (data?.length === 0) return [];
    if (isRep || filter?.value === 0) return data;
    return data.filter((r) => r?.status?.id === filter.value);
  }, [data, filter, isRep]);

  const showSlider = useMemo(() => {
    if (isRep) return true;
    return filteredData.length > 0;
  }, [isRep, filteredData]);

  const sliderRef = useRef(null);
  const onWheelSlider = throttle((e) => {
    if (!sliderRef.current) return;

    if (e.deltaX > 0) {
      sliderRef.current.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef.current.slickPrev();
    }
  }, 30);

  if (isRep && loadingRoleplaysToComplete) return <RoleplaysListShimmer />;
  if (!isRep && loadingRoleplaysToReview) return <RoleplaysListShimmer />;
  if (!data?.length > 0)
    return (
      <div style={{ margin: '2rem 0' }}>
        <AddAssignButton onClick={() => open()} variant="filled" />
      </div>
    );

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselHeader}>
        <span>
          <h1>
            {data?.length || 0}
            {isRep ? ' roleplays to complete' : ' roleplays to review'}
          </h1>
          {!isRep && filter.value !== 0 && (
            <h2>
              ({filteredData?.length || 0} {filter.label.toLowerCase()})
            </h2>
          )}
        </span>
        {!isRep && (
          <div className={styles.carouselSelector}>
            <span>Status</span>
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect
              options={TO_REVIEW_OPTIONS}
              onChange={onFilterChange}
              isSearchable={false}
              isClearable={false}
              value={filter}
            />

            {FeatureFlag.enabled("ROLEPLAY_FILTERS") && <Filter page='roleplay'/>}
          </div>
        )}
      </div>
      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        <Grid item xs={12} md={2}>
          <AddAssignButton onClick={open} />
        </Grid>
        <Grid item xs={12} md={10}>
          {showSlider && (
            <div className={styles.sliderWrapper} onWheel={onWheelSlider}>
              <Slider ref={sliderRef} {...slider_settings}>
                {filteredData.map((roleplay) => (
                  <DashboardRoleplayCard key={roleplay?.uuid} roleplay={roleplay} />
                ))}
              </Slider>
            </div>
          )}
          {!isRep && filteredData.length === 0 && (
            <div className={styles.empty}>
              <span>
                There are no roleplays to review with the <b>"{filter.label}"</b> status
              </span>
              <span>Try with another filter</span>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const slider_settings = {
  dots: false,
  infinite: false,
  speed: 700,
  arrows: true,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplay: false,
  prevArrow: <img src={imageBasePath + 'next.svg'} alt="Prev" />,
  nextArrow: <img src={imageBasePath + 'next.svg'} alt="Next" />,
  className: styles.carouselItem,
  responsive: [
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

export default RoleplaysDashboardsList;
