import React, { useMemo,useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

import styles from './RoleplayProgress.module.scss';
import { useGetRoleplayProgressQuery } from 'features/roleplay/Roleplay.queries';
import { useHomeStore } from 'features/home/Home.stores';
import { FeatureFlag } from 'controllers/_exports';

const RoleplayProgress = ({ withHeader = true, home = false }) => {
  const { selectedDateFilter } = useHomeStore();

  const { data = {}, refetch } = useGetRoleplayProgressQuery({
    home,
    time: FeatureFlag.enabled("HOME_FILTERS") ? selectedDateFilter.slug : 'all-time',
  });
  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateFilter])
  
  const statusData = useMemo(() => {
    if (!data?.statuses?.length > 0) return [];
    return data?.statuses?.map(({ roleplay_count, status }) => ({
      value: roleplay_count || 0,
      color: status?.color_code || '#0494fd',
      label: status?.label || '',
    }));
  }, [data?.statuses]);

  const total = useMemo(
    () => data?.total_roleplays || data?.total_submitted_roleplays || 0,
    [data?.total_roleplays, data?.total_submitted_roleplays]
  );

  const options = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: { enabled: total > 0 },
      },
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      cutout: '70%',
      radius: '90%',
    }),
    [total]
  );

  const colors = useMemo(
    () => (total === 0 ? ['#e3e3e3'] : statusData?.map((s) => s?.color)),
    [statusData, total]
  );

  const chartData = useMemo(() => {
    const data = total === 0 ? [1] : statusData?.map((s) => s?.value);
    return {
      labels: statusData?.map((s) => s?.label),
      datasets: [
        {
          data,
          label: '',
          borderWidth: 1,
          backgroundColor: colors,
          borderColor: colors,
          hoverBorderColor: colors,
          hoverBackgroundColor: colors,
          hoverBorderWidth: 5,
          hoverBorderRadius: 0,
          hoverOffset: 4,
        },
      ],
    };
  }, [colors, statusData, total]);

  return (
    <div className={styles.progressGraphContainer}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ padding: 0, flexWrap: 'wrap-reverse' }}
      >
        <Grid item xs={12} sm={3}>
          <div className={styles.legendsContainer}>
            {withHeader && <h1>Progress</h1>}
            {statusData?.map((s, index) => (
              <span className={styles.legend} key={index}>
                <div style={{ border: `3px solid ${s?.color}` }} className={styles.dot} />
                <span>
                  {s?.label} <strong>{s?.value}</strong>
                </span>
              </span>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ height: '200px', position: 'relative', marginTop: '1.5rem' }}
        >
          <div className={styles.totalContainer}>
            <h6 className="heading1">{total}</h6>
            <p className="paragraph">Total</p>
            <p className="paragraph">Roleplays</p>
          </div>
          <Doughnut data={chartData} options={options} />
        </Grid>
      </Grid>
    </div>
  );
};

export default RoleplayProgress;
